const globalConfig = {
  META_TYPE: window.Configuration.META_TYPE,
  META_KEYWORD: window.Configuration.META_KEYWORD,
  META_TITLE: window.Configuration.META_TITLE,
  HEADER_ECFLOGO: window.Configuration.HEADER_ECFLOGO,
  HEADER_CWLOGO: window.Configuration.HEADER_CWLOGO,
  API_PATHWAYS_URL: window.Configuration.API_PATHWAYS_URL,
  API_OFFICIAL_PATHWAYS_URL: window.Configuration.API_OFFICIAL_PATHWAYS_URL,
  API_GRAPH_DETAIL_URL: window.Configuration.API_GRAPH_DETAIL_URL,
  API_GRAPH_DETAIL_URL_OFFICIAL: window.Configuration.API_GRAPH_DETAIL_URL_OFFICIAL,
  API_GRAPH_METRIC_URL: window.Configuration.API_GRAPH_METRIC_URL,
  API_GRAPH_METRIC_NEW_URL: window.Configuration.API_GRAPH_METRIC_NEW_URL,
  API_GRAPH_METRIC_URL_OFFICIAL: window.Configuration.API_GRAPH_METRIC_URL_OFFICIAL,
  API_LEVERS_URL: window.Configuration.API_LEVERS_URL,
  API_LEVER_METRIC_URL: window.Configuration.API_LEVER_METRIC_URL,
  API_REGIONS_URL: window.Configuration.API_REGIONS_URL,
  API_VERSION_URL: window.Configuration.API_VERSION_URL,
  API_KPIS_GRAPHS_DETAILS_URL: window.Configuration.API_KPIS_GRAPHS_DETAILS_URL,
  API_DASHBOARD_STATIC: window.Configuration.API_DASHBOARD_STATIC,
  API_DASHBOARD_DYNAMIC: window.Configuration.API_DASHBOARD_DYNAMIC,
};

export default globalConfig;
