export const URL_PARAMS = {
  visualisation: 'visualisation',
  region: 'region',
  pathway: 'scenario',
  levers: 'levers',
  source: 'source',
};

export const REGION_URL = URL_PARAMS.region;
export const VISUALISATION_URL = URL_PARAMS.visualisation;
export const LEVERS_URL = URL_PARAMS.levers;
export const PATHWAY_URL = URL_PARAMS.pathway;
export const SOURCE_URL = URL_PARAMS.source;
