import { combineReducers } from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import menu from './menuSlice';
import settings from './settingsSlice';

const core = combineReducers({
  settings,
  menu,
  dialog,
});

export default core;
