import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { deleteLockedGraphData } from '../pathways/displaySlice';
import { updateConcatFilters, removeConcatFiltersByIndex } from '../global/filtersSlice';

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState = {
  error: undefined,
};

const errorsSlice = createSlice({
  name: 'errorManagement',
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(updateConcatFilters, deleteLockedGraphData, removeConcatFiltersByIndex), (state) => {
      state.error = undefined;
    });
  },
});

export const { setError } = errorsSlice.actions;
export default errorsSlice.reducer;
