import { createSlice } from '@reduxjs/toolkit';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IFlowsState {
  inProgress: boolean;
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IFlowsState = {
  inProgress: false,
};

const flowsSlice = createSlice({
  name: 'flows',
  initialState,
  reducers: {
    startFlow(state) {
      state.inProgress = true;
    },
    endFlow(state) {
      state.inProgress = false;
    },
  },
});

export const { startFlow, endFlow } = flowsSlice.actions;
export default flowsSlice.reducer;
