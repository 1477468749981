import { createSlice } from '@reduxjs/toolkit';
import { mapCategories, mapSubcategories } from '../../utils/entity-utils';
import { getAllGraphDetails } from './mainContentSlice';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface ICategoriesSlice {
  categories: string[];
  subcategories: { category: string; name: string }[];
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: ICategoriesSlice = {
  categories: [],
  subcategories: [],
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllGraphDetails.fulfilled, (state, action) => {
      state.categories = mapCategories(action.payload);
      state.subcategories = mapSubcategories(action.payload);
    });
  },
});

export default categoriesSlice.reducer;
