import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGlobalDomainSelector } from '../app/store';
import { OFFICIAL_SOURCE } from '../constants';
import { Utils } from '../utils';

export default function useCompareSeries(mapOne, mapTwo, mapOneLocked, mapTwoLocked) {
  const { source: sourceSelected } = useGlobalDomainSelector(({ filters }) => filters.current);
  const { source: sourceLocked } = useGlobalDomainSelector(({ filters }) => filters.locked);
  const comparedGraph = Object.keys(useGlobalDomainSelector(({ filters }) => filters.locked)).length !== 0;
  const currentLoading = useSelector(({ pathwaysDomain }) => pathwaysDomain.display.current.isLoaded);
  const lockedLoading = useSelector(({ pathwaysDomain }) => pathwaysDomain.display.locked.isLoaded);
  let firstSeries = {
    generalSerie: mapOne.length > 0 ? mapOne : null,
    lockedSerie: mapOneLocked.length > 0 ? mapOneLocked : null,
    diff: [],
  };
  let secondSeries = {
    generalSerie: mapTwo.length > 0 ? mapTwo : null,
    lockedSerie: mapTwoLocked.length > 0 ? mapTwoLocked : null,
    diff: [],
  };

  const compareSeries = (firstSerie, secondSerie, diff) => {
    const generalSerie = firstSerie;
    const lockedSerie = secondSerie;

    const firstSeriesNames = Utils.getFields(generalSerie, 'title');
    const secondSeriesNames = Utils.getFields(lockedSerie, 'title');
    diff = Utils.arrayDiff(firstSeriesNames, secondSeriesNames);

    if (sourceSelected === OFFICIAL_SOURCE) {
      diff.forEach((elToDelete) => {
        const index = _.findIndex(lockedSerie, (e) => e.title === elToDelete);
        if (index > -1) lockedSerie.splice(index, 1);
      });
      Utils.mapOrder(lockedSerie, firstSeriesNames, 'title');
    } else {
      diff.forEach((elToDelete) => {
        const index = _.findIndex(generalSerie, (e) => e.title === elToDelete);
        if (index > -1) generalSerie.splice(index, 1);
      });
      Utils.mapOrder(generalSerie, secondSeriesNames, 'title');
    }

    return { generalSerie, lockedSerie, diff };
  };

  if (comparedGraph) {
    if (currentLoading && lockedLoading) {
      if (mapOne.length > 0 && mapOneLocked.length > 0 && sourceSelected !== sourceLocked) {
        const generalFirstSerie = _.cloneDeep(mapOne);
        const lockedFirstSerie = _.cloneDeep(mapOneLocked);
        const diff = [];
        firstSeries = compareSeries(generalFirstSerie, lockedFirstSerie, diff);
      }

      if (mapTwo.length > 0 && mapTwoLocked.length > 0 && sourceSelected !== sourceLocked) {
        const generalSecondSerie = _.cloneDeep(mapTwo);
        const lockedSecondSerie = _.cloneDeep(mapTwoLocked);
        const diff = [];

        secondSeries = compareSeries(generalSecondSerie, lockedSecondSerie, diff);
      }
    }
  }
  return { firstSeries, secondSeries };
}
