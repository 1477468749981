import _ from 'lodash';
import globalConfig from '../../../configs/globalConfigs';
import { MODEL_SOURCE, OFFICIAL_SOURCE } from '../../../constants';

export const getModalInfo = (dataLength, url) => {
  let showModal = false;
  let modalMessage;

  if (dataLength !== 0) {
    return {
      modalMessage,
      showModal,
    };
  }

  showModal = true;
  modalMessage = 'default';

  if (url === globalConfig.API_GRAPH_METRIC_NEW_URL) modalMessage = 'busy';

  return { modalMessage, showModal };
};

export const getResult = (outputs, callInfos, graphDetailsToCompare, isLocked, mapNumber) => {
  const conditionAvailability = mapNumber === 0 ? callInfos[0] && outputs : callInfos[1] && outputs;

  const result = [];

  if (outputs && conditionAvailability) {
    _.uniq(callInfos[mapNumber][graphDetailsToCompare]).forEach((id) => {
      outputs[mapNumber].forEach((element, index) => {
        if (element.id === id && element.id !== 'all' && element.id !== 'nan') {
          element.locked = isLocked;
          if (isLocked) {
            element.index = index;
          }
          result.push(element);
        }
      });
    });
  }

  return result;
};

export const getKpiList = (data) => {
  if (data) {
    const values = [];
    const titles = [];
    for (const item of data) {
      titles.push(item.title);
      values.push((item.data * 100).toFixed(2) + ' %');
    }
    return { value: values, title: titles };
  }
};

export const getApiRequestPayload = (params) => {
  const { metrics, levers, source, region, pathway, dynamicLevers, callInfos, endYear } = params;

  let dimensions = {};
  callInfos.forEach((item, i) => {
    dimensions = {
      ...dimensions,
      [i]: item.dimension ? item.dimension : null,
    };
  });

  let sectors = {};
  callInfos.forEach((item, i) => {
    sectors = { ...sectors, [i]: item.sector ? item.sector : null };
  });

  let data;
  if (source === MODEL_SOURCE) {
    const _levers = {};
    if (typeof pathway === 'string') {
      let _levers2 = levers.map((lever) => lever / 10);
      _levers2 = { [pathway]: { static_levers: _levers2, dynamic_levers: dynamicLevers } };
      _levers[region] = _levers2;
    } else if (typeof pathway === 'object') {
      pathway.forEach((pathway, i) => {
        let _levers2 = levers[i].map((lever) => lever / 10);
        _levers2 = { [pathway]: { static_levers: _levers2, dynamic_levers: dynamicLevers[i] } };
        _levers[region[i]] = { ..._levers[region[i]], ..._levers2 };
      });
    }
    data = {
      levers: _levers,
      outputs: metrics,
      dimension: dimensions,
      max_year: endYear,
    };
  } else if (source === OFFICIAL_SOURCE) {
    data = {
      scenario: pathway,
      region,
      dimension: dimensions,
      sector: sectors,
    };
  }

  return data;
};
