import { combineReducers } from '@reduxjs/toolkit';
import dashboardStatic from './dashboardStaticSlice';
import dashboardDynamic from './dashboardDynamicSlice';

const dashboardDomain = combineReducers({
  dashboardStatic,
  dashboardDynamic,
});

export default dashboardDomain;
