import { Link } from 'react-router-dom';
import styles from './FooterLinks.module.css';

export interface IFooterLinks {
  elements: { url: string; content: string }[];
  type?: string;
}

const FooterLinks: React.FC<IFooterLinks> = ({ elements }) => {
  return (
    <div className={styles.container}>
      <ul>
        {elements.map((element, i) => {
          return (
            <li key={`footerlink_${i}`}>
              <Link to={element.url} key={i}>
                {element.content}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FooterLinks;
