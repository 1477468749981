import _ from 'lodash';
import Utils from './Utils';

/* -------------------------------------------------------------------------------------------------------
  Create and format serie
--------------------------------------------------------------------------------------------------------- */

const getSerieStylePerType = (serieType, yDataLenght) => {
  let serieStylePerType;
  switch (serieType) {
    case 'line':
      serieStylePerType = {
        type: 'line',
        lineStyle: {
          width: 3,
          type: 'solid',
        },
        z: yDataLenght + 1,
      };
      break;
    case 'area':
      serieStylePerType = {
        lineStyle: {
          width: 0,
        },
        areaStyle: {},
        stack: true,
        type: 'line',
      };
      break;
    default: //
  }

  return serieStylePerType;
};

const toSerieFormat = (xData, yData, serieName, serieType, unit, index, locked) => {
  let serie = {
    id: index,
    name: serieName,
    data: [],
    locked,
    symbol: 'none',
    connectNulls: true,
  };

  const serieStylePerType = getSerieStylePerType(serieType, yData.length);
  serie = { ...serie, ...serieStylePerType };

  const toPercentage = (value) => (unit?.includes('%') ? parseFloat(value) * 100 : parseFloat(value));

  const serieData = yData.map((value, index) => {
    const yValue = value === null && value !== 0 ? null : toPercentage(value);
    return [xData[index], yValue];
  });

  serie.data = fillMissingSeriesData(xData, serieData);
  return serie;
};

/* -------------------------------------------------------------------------------------------------------
Create missing serie data (needed because echarts (v5.1) doesn't support Axis as value + stacked graph)
--------------------------------------------------------------------------------------------------------- */
const findMidPoints = (p1, p2, points) => {
  const result = [];
  let i = 1;

  while (i <= points) {
    if (p2[1] === null && p1[1] === null) {
      result.push([(i * (p2[0] - p1[0])) / (points + 1) + p1[0], null]);
    } else {
      result.push([(i * (p2[0] - p1[0])) / (points + 1) + p1[0], (i * (p2[1] - p1[1])) / (points + 1) + p1[1]]);
    }
    i++;
  }

  return result;
};

function findLastNonNullYear(data) {
  for (let i = data.length - 1; i >= 0; i--) {
    if (data[i][1] !== null) {
      return data[i][0]; // Return the year (first position) when a non-null value is found in the second position
    }
  }
  return null; // Return null if no non-null values are found in the second position
}

const fillMissingSeriesData = (yearsRange, existingData) => {
  // Add missing years to yearsRange
  const xDataWithAllYears = [];
  for (let i = yearsRange[0]; i <= yearsRange[yearsRange.length - 1]; i++) {
    xDataWithAllYears.push(i);
  }

  const filledData = [];
  xDataWithAllYears.forEach((year) => {
    const dataPointExist = existingData.find((element) => element[0] === year);

    /**
     * Determine the last year with available data (non-null values).
     * Important to avoid making extrapolation about data beyond the last known data point.
     */
    const lastYearWithData = findLastNonNullYear(existingData);

    if (dataPointExist) {
      filledData.push(dataPointExist);
    } else {
      const isMidPointAlreadyCreated = filledData.find((element) => element[0] === year);
      if (isMidPointAlreadyCreated) return;

      // Calculate and add missing data points between existing data points
      if (year <= lastYearWithData) {
        const previousPoint = Utils.getClosestLowerValue(yearsRange, year);
        const nextPoint = Utils.getClosestUpperValue(yearsRange, year);
        const numberOfMissingDataPoint = nextPoint - previousPoint - 1;
        const dataPreviousPoint = existingData.find((element) => element[0] === previousPoint);
        const dataNextPoint = existingData.find((element) => element[0] === nextPoint);
        const midPoints = findMidPoints(dataPreviousPoint, dataNextPoint, numberOfMissingDataPoint);

        if (midPoints) {
          midPoints?.forEach((midPoint) => {
            filledData.push(midPoint);
          });
        }
      } else {
        // Fill in missing data points beyond the last known data year with null values
        filledData.push([year, null]);
      }
    }
  });

  return filledData;
};

/* -------------------------------------------------------------------------------------------------------
Other helpers
--------------------------------------------------------------------------------------------------------- */

const formatValueWithUnits = (val) => {
  if (val > 1000000000) {
    return Math.round((val / 1000000000) * 100) / 100 + ' bn';
  } else if (val > 1000000) {
    return Math.round((val / 1000000) * 100) / 100 + ' m';
  } else if (val >= 1000) {
    return Math.round((val / 1000) * 100) / 100 + ' k';
  } else if (val < 1) {
    return Math.round(val * 1000) / 1000;
  } else {
    return Math.round(val * 100) / 100;
  }
};

const transformSeries = (seriesData, xData, cat) => {
  const contentHeaders = [cat, ...xData];
  const contentRows = [];

  seriesData.forEach((serie) => {
    const serieData = [];
    serieData.push(serie.name);
    serie.data.forEach((element) => {
      if (xData.includes(element[0])) {
        if (element[1]) {
          serieData.push(element[1].toString().replace(/,/g, '.'));
        } else {
          serieData.push(undefined);
        }
      }
    });
    contentRows.push(serieData);
  });

  const allContent = [];
  allContent.push(contentHeaders);
  contentRows.forEach((contentRow) => {
    allContent.push(contentRow);
  });

  return allContent;
};

const exportGraphAsCsv = (series, xData) => {
  const seriesData = _.cloneDeep(series.option.series).reverse();
  const allContent = transformSeries(seriesData, xData, 'Categories');

  let csvContent = '';
  allContent?.forEach((row) => {
    csvContent += row.join(',') + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', objUrl);
  link.setAttribute('download', 'Export.csv');
  link.click();
};

const createDataViewTable = (opts, xData) => {
  const series = opts.series.reverse();
  const allContent = transformSeries(series, xData, '');

  let table = '<table style="width:100%;text-align:center;border: 1px solid;border-collapse:collapse;"><tbody>';

  allContent.forEach((rows, index) => {
    index === 0
      ? (table += '<tr style="border:1px solid black;background:grey;color:white;font-weight:bold;position:sticky;top:0">')
      : (table += '<tr style="border:1px solid">');

    rows.forEach((row, i) => {
      index === 0
        ? (table += `<td style="border:1px solid black;padding:3px 10px">${row}</td>`)
        : i === 0
        ? (table += `<td style="text-align:left;width:200pxborder:1px solid black;padding:3px 10px;background:grey;color:white;font-weight:bold;white-space: nowrap;position:sticky;left: 0">${row}</td>`)
        : (table += `<td style="border:1px solid black;padding:3px 10px">${row !== undefined ? formatValueWithUnits(row) : '-'}</td>`);
    });
    table += '</tr>';
  });

  table += '</tbody></table>';
  return table;
};

const tooltipFormatter = (params, xData, unit) => {
  const filteredParams = params.filter((param) => xData.includes(Number(param.name)));

  if (filteredParams.length > 0) {
    const data = `<p style="padding:2px;font-weight: 700; font-size:10px">${filteredParams[0].name}</p>`;
    let toolTipList = '';
    for (let i = filteredParams.length - 1; i >= 0; i--) {
      if (filteredParams[i].value[1] !== null) {
        const test = formatValueWithUnits(filteredParams[i].value[1]);
        toolTipList += `<p style="font-size:10px">${filteredParams[i].marker}<span style="width:200px;word-wrap:break-word;">${filteredParams[i].seriesName}: </span><span style="font-weight:700">${test} ${unit}</span></p>`;
      }
    }
    return data + toolTipList;
  } else {
    return null;
  }
};

const createToolBox = (xData, graphInfoUnit, graphTitle) => {
  const toolbox = {
    show: true,
    feature: {
      mark: { show: true },
      dataView: {
        readOnly: false,
        lang: [`Data Table (${graphInfoUnit})`, 'Close', 'Refresh'],
        backgroundColor: 'white',
        textareaBorderColor: 'white',
        textColor: 'black',
        buttonColor: '#10b596',
        optionToContent: (opts) => createDataViewTable(opts, xData),
      },
      myExportAsCsv: {
        show: true,
        title: 'Export As CSV',
        icon: 'M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z',
        onclick: (series) => exportGraphAsCsv(series, xData),
      },
      saveAsImage: {
        show: true,
        title: 'Save As PNG',
        name: graphTitle ? graphTitle.split(' ').join('_').toLowerCase() : 'Graph',
        icon: 'M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 384c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96z',
      },
    },
    itemSize: 15,
    iconStyle: {
      color: 'white',
      borderWidth: 1.5,
      borderColor: 'grey',
    },
  };

  return toolbox;
};

export { createDataViewTable, createToolBox, exportGraphAsCsv, fillMissingSeriesData, formatValueWithUnits, toSerieFormat, tooltipFormatter };
