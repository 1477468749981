import { useEffect, useState } from 'react';

export const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverFlow] = useState(undefined);
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);

  const getWindowWidth = () => {
    if (ref) {
      const currentWidth = document.documentElement.clientWidth;
      setWindowWidth(currentWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', getWindowWidth);
    return () => {
      window.removeEventListener('resize', getWindowWidth);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!ref) return;
    const hasOverflow = ref.current.scrollWidth > ref.current.clientWidth;
    setIsOverFlow(hasOverflow);
  }, [ref, windowWidth, callback]);

  return isOverflow;
};

export default useIsOverflow;
