import { useGlobalDomainSelector } from '../app/store';
import { Utils } from '../utils';

export default function useFindMinMaxSeries(firstSeries, secondSeries, useIndividualValuesCurrent, useIndividualValuesLocked) {
  const comparedGraph = Object.keys(useGlobalDomainSelector(({ filters }) => filters.locked)).length !== 0;

  // Initialize the minMax object to store results
  const minMax = {
    mapOne: { max: null, min: null },
    mapTwo: { max: null, min: null },
  };

  // Helper function to find the minimum and maximum values from the data. Relevant for line graph
  const getMinMaxFromValues = (result) => {
    const minMaxFromResult = Utils.findMinMaxValuesFromArray(result);
    return { min: minMaxFromResult.min, max: minMaxFromResult.max };
  };

  // Helper function to find the minimum and maximum values from the sum of positive and negative values. Relevant for stacked area graphs with total line displayed.
  const getMinMaxFromTotalSums = (result) => {
    // Sum positive values
    const positiveSums = result.reduce((a, b) =>
      a?.map((c, i) => {
        if (c >= 0 && b[i] >= 0) {
          return c + b[i];
        } else if (c <= 0 && b[i] <= 0) {
          return 0;
        } else if (c >= 0 && b[i] <= 0) {
          return c + 0;
        } else if (c <= 0 && b[i] >= 0) {
          return b[i] + 0;
        } else {
          return 0;
        }
      }),
    );

    // Sum negative values
    const negativeSums = result.reduce((a, b) =>
      a?.map((c, i) => {
        if (c >= 0 && b[i] >= 0) {
          return 0;
        } else if (c <= 0 && b[i] <= 0) {
          return c + b[i];
        } else if (c >= 0 && b[i] <= 0) {
          return 0 + b[i];
        } else if (c <= 0 && b[i] >= 0) {
          return c + 0;
        } else {
          return 0;
        }
      }),
    );

    // Calculate the rounded minimum and maximum from the sums
    const max = Math.max(...positiveSums);
    const min = Math.min(...negativeSums);

    return { min, max };
  };

  // Helper function to find the minimum and maximum values for data arrays with different units
  const regroupDataAndFindMinMax = (data, useIndividualValues) => {
    const result = [];
    data.forEach((element) => {
      result.push(element.data);
    });

    let minMax;
    if (useIndividualValues) {
      minMax = getMinMaxFromValues(result);
    } else {
      minMax = getMinMaxFromTotalSums(result);
    }

    return { max: minMax.max, min: minMax.min };
  };

  // Main function to calculate the minimum and maximum values for currentSerie and lockedSerie
  const calculateMinMax = (currentSerie, lockedSerie, useIndividualValues) => {
    const currentSerieData = regroupDataAndFindMinMax(currentSerie, useIndividualValues);
    const lockedSerieData = regroupDataAndFindMinMax(lockedSerie, useIndividualValues);

    // Find the overall minimum and maximum from the two series
    const max = Math.max(currentSerieData.max, lockedSerieData.max);
    const min = Math.min(currentSerieData.min, lockedSerieData.min);

    return { min, max };
  };

  // Perform calculations only if comparedGraph is active and data is available for both series
  if (comparedGraph) {
    if (firstSeries?.generalSerie?.length > 0 && firstSeries?.lockedSerie?.length > 0) {
      const result = calculateMinMax(firstSeries.generalSerie, firstSeries.lockedSerie, useIndividualValuesCurrent);
      minMax.mapOne.max = result.max;
      minMax.mapOne.min = result.min;
    }

    if (secondSeries?.generalSerie?.length > 0 && secondSeries?.lockedSerie?.length > 0) {
      const result = calculateMinMax(secondSeries.generalSerie, secondSeries.lockedSerie, useIndividualValuesLocked);
      minMax.mapTwo.max = result.max;
      minMax.mapTwo.min = result.min;
    }
  }

  return minMax;
}
