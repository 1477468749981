export const MENU_ITEMS = [
  {
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    name: 'Pathways',
    path: '/pathways',
  },
  {
    name: 'Model resources',
    path: '/model-resources',
  },
  {
    name: 'FAQ',
    path: '/faq',
  },
  {
    name: 'Data',
    path: '/data',
  },
  {
    name: 'Community',
    path: '/community',
  },
  {
    name: 'News',
    path: '/news',
  },
  {
    name: 'Contact us',
    path: '/contact-us',
  },
  {
    name: 'Legal',
    path: '/legal',
  },
];
