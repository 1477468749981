import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import core from '../store/core/index';
import dashboardDomain from '../store/dashboard/index';
import globalDomain from '../store/global/index';
import pathwaysDomain from '../store/pathways/index';

export const store = configureStore({
  reducer: {
    core,
    globalDomain,
    pathwaysDomain,
    dashboardDomain,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useCoreDomainSelector = <TSelected>(selector: (_state: RootState['core']) => TSelected) =>
  useAppSelector((_state) => selector(_state.core));
export const usePathwaysDomainSelector = <TSelected>(selector: (_state: RootState['pathwaysDomain']) => TSelected) =>
  useAppSelector((_state) => selector(_state.pathwaysDomain));
export const useDashboardDomainSelector = <TSelected>(selector: (_state: RootState['dashboardDomain']) => TSelected) =>
  useAppSelector((_state) => selector(_state.dashboardDomain));
export const useGlobalDomainSelector = <TSelected>(selector: (_state: RootState['globalDomain']) => TSelected) =>
  useAppSelector((_state) => selector(_state.globalDomain));
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
