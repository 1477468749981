import { createToolBox, formatValueWithUnits, tooltipFormatter, toSerieFormat } from './mapCreatorHelper';

export const getSeries = (results, callInfos, customLegend, mapNumber) => {
  if (!results.length) return;
  if (!callInfos) return;
  const graphInfo = typeof mapNumber === 'number' ? callInfos[mapNumber] : callInfos[0];
  if (!graphInfo) return;

  const series = [];
  const xData = results[0]?.timeAxis;
  const regionValuesPerGranularity = [];
  const unit = graphInfo?.Unit;

  const sum = (r, a) =>
    r.map((b, i) => {
      if (b === null || a[i] === null) return null;
      return a[i] + b;
    });

  results.forEach((item) => {
    const yData = item.data;
    const dataName = item.name;
    const serieTitle = customLegend ? item.region + ' - ' + item.pathway : item.title;
    const serieType = item.title === 'total' ? 'line' : graphInfo.graphType;
    const locked = item.locked;
    const index = item.index;

    regionValuesPerGranularity.push(yData);
    series.push(toSerieFormat(xData, yData, item.name ? dataName : serieTitle, serieType, unit, index, locked));
  });

  if (graphInfo.graphStacked === 'true') {
    series.unshift(toSerieFormat(xData, regionValuesPerGranularity.reduce(sum), 'Total', 'line', unit));
  }

  return series;
};

export const getOptions = (results, callInfos, customLegend, isLocked, legendVisible, mapNumber, max) => {
  const graphInfoUnit = typeof mapNumber === 'number' ? callInfos[mapNumber]?.Unit : callInfos[0]?.Unit;
  if (!callInfos) return;
  const graphInfo = typeof mapNumber === 'number' ? callInfos[mapNumber] : callInfos[0];
  if (!graphInfo) return;

  const xData = results[0]?.timeAxis;

  const toolbox = createToolBox(xData, graphInfoUnit, graphInfo?.Name);
  const series = getSeries(results, callInfos, customLegend, mapNumber);
  const legendOrder = series;
  const legendData = [];
  if (legendOrder !== undefined) {
    for (let i = 0; i < legendOrder.length; i++) {
      legendData.push(legendOrder[i].name);
    }
  }

  const legendList = legendData.reverse();

  const options = {
    id: isLocked ? `generatedtoolchart${mapNumber}` : `currenttoolchart${mapNumber}`,
    animation: false,
    series,
    legend:
      mapNumber === 'scenario'
        ? {
            icon: 'circle',
            show: legendVisible,
            inverseOrder: false,
            type: 'scroll',
            orient: 'horizontal',
            top: 'bottom',
            textStyle: {
              width: 100,
              fontSize: 11,
              overflow: 'none',
              justifyContent: 'center',
            },
          }
        : null,
    color: [
      '#FF0000',
      '#347f83',
      '#2291be',
      '#F9E559',
      '#ffa361',
      '#10b596',
      '#00264d',
      '#FDB813',
      '#62C2CC',
      '#E4F6F8',
      '#EEF66C',
      '#d44892',
      '#EE88CD',
      '#87c78f',
      '#A5F2F3',
      '#F1FAC0',
      '#97b2cf',
    ],
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      scale: true,
      axisTick: { show: true },
      axisLabel: {
        show: true,
        width: 100,
        interval: legendVisible ? 4 : 9,
      },
      axisLine: { show: true },
    },
    yAxis: {
      type: 'value',
      name: graphInfoUnit,
      nameLocation: 'middle',
      nameGap: 35,
      max: max?.max ? (graphInfoUnit?.includes('%') ? Math.ceil(max.max * 100) : max.max) : null,
      min: max?.min ? (graphInfoUnit?.includes('%') ? Math.floor(max.min * 100) : max.min) : null,
      nameTextStyle: {
        fontWeight: 'bolder',
      },
      axisLine: {
        show: true,
        symbol: 'none',
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        interval: 100,
      },
      axisLabel: {
        inside: false,
        margin: 10,
        formatter: formatValueWithUnits,
      },
    },
    calculable: true,
    toolbox: { ...toolbox },
    splitLine: {
      show: true,
      lineStyle: {
        type: 'dashed',
        opacity: 0.2,
      },
    },
    grid:
      mapNumber === 'scenario'
        ? {
            left: '20',
            right: '40',
            containLabel: true,
          }
        : null,
    tooltip: {
      show: true,
      trigger: 'axis',
      confine: true,
      width: 25,
      transitionDuration: 0.1,
      hideDelay: 200,
      formatter: (params) => tooltipFormatter(params, xData, graphInfoUnit),
      order: 'seriesDesc',
    },
    media:
      mapNumber === 'scenario'
        ? null
        : [
            {
              option: {
                grid: {
                  left: '30',
                  right: legendVisible ? '170' : '25',
                  top: '40',
                  containLabel: true,
                },
                legend: {
                  data: legendList,
                  icon: 'circle',
                  show: legendVisible,
                  type: 'scroll',
                  orient: 'vertical',
                  width: 6,
                  top: 50,
                  right: 20,
                  bottom: 60,
                  itemGap: 10,
                  textStyle: {
                    width: 100,
                    fontSize: 11,
                    overflow: 'break',
                    justifyContent: 'right',
                  },
                },
              },
            },
            {
              query: {
                maxWidth: 900,
              },
              option: {
                grid: {
                  left: '30',
                  right: '25',
                  containLabel: true,
                },
                legend: {
                  data: legendList,
                  icon: 'circle',
                  show: legendVisible,
                  type: 'scroll',
                  orient: 'horizontal',
                  width: '100%',
                  bottom: 0,
                  justifyContent: 'center',
                  right: 'center',
                  itemGap: 5,
                  textStyle: {
                    width: 100,
                    fontSize: 11,
                    overflow: 'none',
                    justifyContent: 'center',
                  },
                },
              },
            },
          ],
  };

  return options;
};

const mapDataHelper = {
  getSeries,
  getOptions,
};

export default mapDataHelper;
