import PropTypes from 'prop-types';
import React from 'react';
import Progress from '../../atoms/Progress';

function Suspense(props) {
  return <React.Suspense fallback={<Progress {...props.loadingProps} />}>{props.children}</React.Suspense>;
}

Suspense.propTypes = {
  loadingProps: PropTypes.object,
};

Suspense.defaultProps = {
  loadingProps: {
    delay: 0,
  },
};

export default Suspense;
