import { useDispatch } from 'react-redux';
import modalStyle from '../components/UI/modals/Modal.module.css';
import { openDialog } from '../store/core/dialogSlice';

function useOpenDialog(dialog) {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      openDialog({
        class: modalStyle.modal,
        overlay: modalStyle.OverlayInfo,
        close: true,
        children: dialog,
      }),
    );
}

export default useOpenDialog;
