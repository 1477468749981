export const COMPARE_SCENARIO_ADD = 'compare-scenario';
export const COMPARE_SCENARIO_REPLACE = 'compare-scenario-replace';
export const SINGLE_KPI_SCENARIO = 'single-kpi';
export const CALIBRATION_ADD = 'calibration';
export const CALIBRATION_REPLACE = 'calibration-replace';

export const SCENARIOS = [
  {
    name: 'Lock your scenario',
    value: COMPARE_SCENARIO_ADD,
  },
  {
    name: 'Replace locked scenario',
    value: COMPARE_SCENARIO_REPLACE,
  },
  { name: 'Lock your scenario', value: SINGLE_KPI_SCENARIO },
  { name: 'Lock your scenario', value: CALIBRATION_ADD },
  { name: 'Replace locked scenario', value: CALIBRATION_REPLACE },
];
