import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import globalConfig from '../../configs/globalConfigs';
import { mapDashboardDynamic } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface dataSample {
  data: number[];
  id: string;
  name: string;
  naming_convention: string;
  pathway: string;
  region: string;
  timeAxis: number[];
  title: string;
}

interface IDashboardDynamicSlice {
  loading: boolean;
  error: null | string | undefined;
  data: [] | dataSample[];
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IDashboardDynamicSlice = {
  loading: false,
  error: null,
  data: [],
};

const dashboardDynamicSlice = createSlice({
  name: 'dashboardDynamic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDynamicDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDynamicDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getAllDynamicDashboard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default dashboardDynamicSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */
export const getAllDynamicDashboard = createAsyncThunk(
  'dashboardDynamic/fetchDashboardDynamic',
  async ({ region, pathway }: { region: string; pathway: string }) => {
    const result = await axios.post(globalConfig.API_DASHBOARD_DYNAMIC, {
      region,
      scenario: pathway,
    });
    return { data: mapDashboardDynamic(result.data) };
  },
);
