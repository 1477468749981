import _ from 'lodash';
import FooterLogoElement from '../../atoms/FooterLogoElement';
import styles from './FooterLogos.module.css';

interface sampleElement {
  url: string;
  img: string;
  alt: string;
  category?: string;
  externalLink: boolean;
  textRight?: string;
}

export interface IFooterLogos {
  elements: sampleElement[];
  title?: string;
  byCategory?: boolean;
  text?: string;
}

const FooterLogos: React.FC<IFooterLogos> = ({ title, elements, byCategory = false, text }) => {
  let logosToLoopOn = byCategory ? _.groupBy(elements, 'category') : elements;

  let logos;

  if (byCategory) {
    logos = (
      <>
        {Object.entries(logosToLoopOn).map((category, i) => {
          return (
            <div key={`${category[0]}_${i}`}>
              <h3 className={styles.categoriesTitle}>{category[0]}</h3>
              <div className={styles.logosPerCategory}>
                {category[1].map((element: sampleElement, i: number) => {
                  return <FooterLogoElement element={element} key={`${element.alt}_${i}`} />;
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  } else {
    logos = (
      <>
        {Object.values(logosToLoopOn).map((element, i) => {
          return <FooterLogoElement element={element} key={`${element.alt}_${i}`} />;
        })}
      </>
    );
  }

  return (
    <div className={styles.container}>
      {title && <p className={styles.title}>{title}</p>}
      <div className={styles.logosPerCategoryContainer}>{logos}</div>
      {text && <p className={styles.text}>{text}</p>}
    </div>
  );
};

export default FooterLogos;
