import { HiOutlineMenu } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import climactLogo from '../../../../assets/images/logo-climact.png';
import globalConfig from '../../../../configs/globalConfigs';
import { toggleTopMenu } from '../../../../store/core/menuSlice';
import MenuBurger from '../../molecules/MenuBurger';
import MenuTabs from '../../molecules/MenuTabs';
import headerStyle from './Header.module.css';

const url = {
  euClimate: 'https://europeanclimate.org/',
  be: 'https://www.belgium.be',
  planup: 'https://www.planup.eu/en/about',
  climactHome: 'https://www.climat.be/2050/fr-be/accueil/',
  climact: 'http://www.climact.com/',
};

const HeaderSimple = ({ sticky }) => {
  const dispatch = useDispatch();

  const handleMenuIconClick = () => {
    dispatch(toggleTopMenu());
  };

  return (
    <>
      <header className={`${headerStyle.header} ${sticky ? headerStyle.headerSticky : ''}`}>
        <nav className={headerStyle.nav}>
          <div className={headerStyle.navLeft}>
            {globalConfig.META_TYPE !== 'OTHERS' && (
              <a href={url.climact} target='_blank' rel='noopener noreferrer' className={headerStyle.logo}>
                <img src={climactLogo} alt='Logo climact' />
              </a>
            )}
            <Link to='/dashboard' className={headerStyle.webtoolTitle}>
              {globalConfig.META_TYPE === 'EUROPE' ? (
                <div>2050 Pathways Explorer {window.env.REACT_APP_ENV !== 'prod' && <span>- TEST SERVER</span>}</div>
              ) : (
                <h1>
                  2050 Pathways Explorer - <span>Belgium</span>
                </h1>
              )}
            </Link>
          </div>
          <MenuTabs />
        </nav>

        <button className={headerStyle.burgerMenuBtn} onClick={handleMenuIconClick}>
          <HiOutlineMenu size={25} color='var(--color-text)' />
        </button>
      </header>

      <MenuBurger />
    </>
  );
};

export default HeaderSimple;
