import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import AppContext from '../../../../AppContext';
import { generateSettings, resetSettings, setSettings } from '../../../../store/core/settingsSlice';
import { withRouter } from '../../withRouter';
import Layouts from '../Layouts/Layouts';

class MainLayout extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      awaitRender: false,
      routes,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { pathname } = props.location;

    const matched = matchRoutes(state.routes, pathname)[0];

    let newSettings = props.settings;

    if (state.pathname !== pathname) {
      if (matched && matched.route.settings) {
        const routeSettings = matched.route.settings;

        newSettings = generateSettings(props.defaultSettings, routeSettings);
        if (!_.isEqual(props.settings, newSettings)) {
          props.setSettings(newSettings);
        }
      } else if (!_.isEqual(props.settings, props.defaultSettings)) {
        newSettings = _.merge({}, props.defaultSettings);
        props.resetSettings();
      }
    }

    return {
      awaitRender: !_.isEqual(props.settings, newSettings),
      pathname,
    };
  }

  render() {
    const { settings } = this.props;
    const Layout = Layouts[settings.layout.style];

    return !this.state.awaitRender ? <Layout {...this.props} /> : null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSettings,
      resetSettings,
    },
    dispatch,
  );
}

function mapStateToProps({ core }) {
  return {
    defaultSettings: core.settings.defaults,
    settings: core.settings.current,
  };
}

MainLayout.contextType = AppContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(React.memo(MainLayout)));
