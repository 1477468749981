import { Link } from 'react-router-dom';
import styles from './FooterLogoElement.module.css';

interface sampleElement {
  url: string;
  img: string;
  alt: string;
  category?: string;
  externalLink: boolean;
  textRight?: string;
}

export interface IFooterLogoElement {
  element: sampleElement;
}

const FooterLogoElement: React.FC<IFooterLogoElement> = ({ element }) => {
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        {element.externalLink ? (
          <a href={element.url} target='_blank' rel='noopener noreferrer'>
            <img src={element.img} alt={element.alt} />
          </a>
        ) : (
          <Link to={element.url}>
            <img src={element.img} alt={element.alt} />
          </Link>
        )}
        {element.textRight && <p className={styles.textRight}>{element.textRight}</p>}
      </div>
    </div>
  );
};

export default FooterLogoElement;
