import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import MainLayoutConfigs from '../../components/layouts/pages/Layouts/LayoutsConfig';
import SettingsConfig from '../../configs/settingsConfig';

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialSettings = getInitialSettings();

const initialState = {
  initial: initialSettings,
  defaults: _.merge({}, initialSettings),
  current: _.merge({}, initialSettings),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(state, action) {
      const current = generateSettings(state.defaults, action.payload);
      state.current = current;
    },
    setInitialSettings() {
      _.merge({}, initialState);
    },
    setDefaultSettings(state, action) {
      const defaults = generateSettings(state.defaults, action.payload);
      state.defaults = _.merge({}, defaults);
      state.current = _.merge({}, defaults);
    },
    resetDefaultSettings(state) {
      state.defaults = _.merge({}, state.defaults);
      state.current = _.merge({}, state.defaults);
    },
  },
});

export const { setSettings, setDefaultSettings, setInitialSettings, resetSettings } = settingsSlice.actions;
export default settingsSlice.reducer;

/* ----------------------------------------------------------------
      SETTINGS
---------------------------------------------------------------- */

export function getInitialSettings() {
  const defaultLayoutStyle = SettingsConfig.layout && SettingsConfig.layout.style ? SettingsConfig.layout.style : 'simple';
  const layout = {
    style: defaultLayoutStyle,
    config: MainLayoutConfigs[defaultLayoutStyle].defaults,
  };
  return _.merge({}, { layout }, SettingsConfig);
}

export const generateSettings = (_defaultSettings, _newSettings) => {
  return _.merge(
    {},
    _defaultSettings,
    _newSettings && _newSettings.layout && _newSettings.layout.style
      ? {
          layout: {
            config: MainLayoutConfigs[_newSettings.layout.style].defaults,
          },
        }
      : {},
    _newSettings,
  );
};
