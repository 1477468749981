import { createSlice } from '@reduxjs/toolkit';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface ICalibrationState {
  selectedYear: number;
  typeOfGap: 'relative' | 'absolute' | 'trend';
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: ICalibrationState = {
  selectedYear: 2015,
  typeOfGap: 'relative',
};

const calibrationSlice = createSlice({
  name: 'calibration',
  initialState,
  reducers: {
    updateSelectedYear(state, action) {
      state.selectedYear = action.payload;
    },
    updateTypeOfGap(state, action) {
      state.typeOfGap = action.payload;
    },
  },
});

export const { updateSelectedYear, updateTypeOfGap } = calibrationSlice.actions;
export default calibrationSlice.reducer;
