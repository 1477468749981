import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import axios from 'axios';
import type { RootState } from '../../app/store';
import globalConfig from '../../configs/globalConfigs';
import { mapMetrics } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IMetric {
  lever_name: string;
  metrics: string;
  figure_caption: string;
}

interface IMetricsState extends EntityState<IMetric> {
  selectedMetric: null | IMetric;
  loading: Boolean;
  error?: null | string;
  ids: [];
  entities: {};
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const metricsAdapter = createEntityAdapter<IMetric>();

const initialState: IMetricsState = metricsAdapter.getInitialState({
  selectedMetric: null,
  loading: false,
  error: null,
  isMetricsAvailable: false,
  ids: [],
  entities: {},
});

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    updateSelectedMedtric(state, action) {
      state.selectedMetric = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMetrics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMetrics.fulfilled, (state, action) => {
      state.loading = false;
      metricsAdapter.setAll(state, action.payload);
    });
    builder.addCase(getAllMetrics.rejected, (state, action) => {
      state.loading = false;
      metricsAdapter.setAll(initialState, []);
      state.error = action.error.message;
    });
  },
});

export const { updateSelectedMedtric } = metricsSlice.actions;
export default metricsSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */

export const getAllMetrics = createAsyncThunk(
  'metrics/fetchMetrics',
  async (initialPost: { region: string; maxYear: number; lever: string; lever_position: number; dynamic_levers: {} }) => {
    const { region, maxYear, lever, lever_position, dynamic_levers } = initialPost;
    const result = await axios.post(globalConfig.API_LEVER_METRIC_URL, {
      region,
      max_year: maxYear,
      lever,
      lever_position,
      dynamic_levers,
    });
    return mapMetrics(result.data);
  },
);

/* ----------------------------------------------------------------
      SELECTORS
---------------------------------------------------------------- */

export const metricsSelector = metricsAdapter.getSelectors((state: RootState) => state.pathwaysDomain.metrics);

export const metricsInformation = (leverName: string) =>
  createSelector(metricsSelector.selectAll, (elements: IMetric[]) => elements.filter((element) => element.lever_name === leverName) || []);
