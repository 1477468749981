export const SOURCE_OPTIONS = [
  {
    value: 'model',
    name: 'Pathways Explorer Model (dynamic data)',
    disabled: false,
  },
  {
    value: 'static',
    name: 'Other sources (static data)',
    disabled: false,
  },
];

export const MODEL_SOURCE = SOURCE_OPTIONS[0].value;
export const OFFICIAL_SOURCE = SOURCE_OPTIONS[1].value;
