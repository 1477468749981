import React, { useState } from 'react';
import { useTimeout } from '../../../../hooks';
import styles from './Progress.module.css';

export interface IProgress {
  /**
   * Is there a delay before displaying the spinner ? If yes how long should it be ?
   */
  delay?: number | null;
  /**
   * Spinner type : dotSpin / dotPulse / dotFlashing
   */
  type?: string;
  /**
   * Is there a texte to display below the spinner ? If yes what should it be ?
   */
  loadingText?: string;
}

const Progress: React.FC<IProgress> = ({ delay = 0, type = 'dotSpin', loadingText }) => {
  const [showLoading, setShowLoading] = useState(!delay);

  useTimeout(() => {
    setShowLoading(true);
  }, delay);

  if (!showLoading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <div className={styles.stage}>
          <div className={styles[type]}></div>
        </div>
        {loadingText && <p className={styles.loadingText}>{loadingText}</p>}
      </div>
    </div>
  );
};

export default Progress;
