import beLogo from '../assets/images/beLogo.png';
import canEuropeLogo from '../assets/images/canEurope.png';
import euCalcLogo from '../assets/images/eucalc.png';
import deLogo from '../assets/images/govDE.png';
import ukLogo from '../assets/images/govUK.png';
import climateWorksLogo from '../assets/images/logo-climateworks.png';
import co2ValueLogo from '../assets/images/logo-co2value.png';
import ecfLogoEur from '../assets/images/logo-ecf.png';
import cwLogoEur from '../assets/images/logo-planup.png';

const url = {
  euClimate: 'https://europeanclimate.org/',
  be: 'https://climat.be/2050-en',
  canEurope: 'https://caneurope.org/',
  de: 'https://www.bundesregierung.de/breg-fr',
  uk: 'https://www.gov.uk/government/organisations/department-for-business-energy-and-industrial-strategy',
  planup: 'https://www.planup.eu/en/about',
  climactHome: 'https://www.climat.be/2050/fr-be/accueil/',
  climact: 'http://www.climact.com/',
  euCalc: 'https://www.european-calculator.eu/',
  climateWorks: 'https://www.climateworks.org/',
  co2Value: 'https://co2value.eu/',
};

export const FOOTER_LINKS = [
  { content: 'Model resources', url: '/model-resources' },
  { content: 'FAQ', url: '/faq' },
  { content: 'Data', url: '/data' },
  { content: 'Community', url: '/community' },
  { content: 'News', url: '/news' },
  { content: 'How to videos', url: '/how-to-videos' },
  { content: 'Contact us', url: '/contact-us' },
  { content: 'Legal', url: '/legal' },
];

export const FOOTER_LOGOS = [
  {
    img: beLogo,
    url: url.be,
    category: 'Governments',
    alt: 'Logo Belgium.be',
    externalLink: true,
  },
  {
    img: deLogo,
    url: url.de,
    category: 'Governments',
    alt: 'Logo Bundesregierung',
    externalLink: true,
  },
  {
    img: ukLogo,
    url: url.uk,
    category: 'Governments',
    alt: 'Logo GOV.UK',
    externalLink: true,
  },
  {
    img: ecfLogoEur,
    url: url.euClimate,
    category: 'NGOs',
    alt: 'Logo European Climate Foundation',
    externalLink: true,
  },
  {
    img: climateWorksLogo,
    url: url.climateWorks,
    category: 'NGOs',
    alt: 'Logo ClimateWorks',
    externalLink: true,
  },
  {
    img: canEuropeLogo,
    url: url.canEurope,
    category: 'NGOs',
    alt: 'Logo ClimateWorks',
    externalLink: true,
  },
  {
    img: cwLogoEur,
    url: url.planup,
    category: 'Projects',
    alt: 'Logo Plan Up',
    externalLink: true,
  },
  {
    img: euCalcLogo,
    url: url.euCalc,
    category: 'Projects',
    alt: 'Logo EuCalc',
    externalLink: true,
  },
  {
    img: co2ValueLogo,
    url: url.co2Value,
    category: 'Projects',
    alt: 'Logo CO2Value',
    externalLink: true,
  },
];
