import { RiArrowDropDownLine } from 'react-icons/ri';
import { Link, NavLink } from 'react-router-dom';
import menuTabsStyle from './MenuTabs.module.css';

const MenuTabs = () => {
  return (
    <div className={menuTabsStyle.container}>
      <ul className={menuTabsStyle.elements}>
        <li>
          <NavLink className={({ isActive }) => (isActive ? menuTabsStyle.active : '')} to='/dashboard'>
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => (isActive ? menuTabsStyle.active : '')} to='/pathways'>
            Pathways
          </NavLink>
        </li>
        <li className={menuTabsStyle.dropDown}>
          About
          <RiArrowDropDownLine size={20} className={menuTabsStyle.menuDropDownIcon} />
          <ul className={menuTabsStyle.dropDownContent}>
            <li>
              <Link to='/model-resources'>Model resources</Link>
            </li>
            <li>
              <Link to='/faq'>FAQ</Link>
            </li>
            <li>
              <Link to='/data'>Data</Link>
            </li>
            <li>
              <Link to='/community'>Community</Link>
            </li>
            <li>
              <Link to='/news'>News</Link>
            </li>
            <li>
              <Link to='/how-to-videos'>How to videos</Link>
            </li>
            <li>
              <Link to='/contact-us'>Contact us</Link>
            </li>
            <li>
              <Link to='/legal'>Legal</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default MenuTabs;
