import { createSlice } from '@reduxjs/toolkit';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IMenuSlice {
  topMenuOpen: boolean;
  bottomMenuOpen: boolean;
  sideMenuOpen: boolean;
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IMenuSlice = {
  topMenuOpen: false,
  bottomMenuOpen: false,
  sideMenuOpen: true,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleTopMenu(state) {
      state.topMenuOpen = !state.topMenuOpen;
      state.bottomMenuOpen = false;
    },
    toggleBottomMenu(state) {
      state.bottomMenuOpen = !state.bottomMenuOpen;
      state.topMenuOpen = false;
    },
    closeTopMenu(state) {
      state.topMenuOpen = false;
    },
    toggleSideMenu(state) {
      state.sideMenuOpen = !state.sideMenuOpen;
    },
  },
});

export const { toggleTopMenu, toggleBottomMenu, closeTopMenu, toggleSideMenu } = menuSlice.actions;
export default menuSlice.reducer;
