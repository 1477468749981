import { useSelector } from 'react-redux';
import { FOOTER_LINKS, FOOTER_LOGOS } from '../../../../constants';
import FooterLinks from '../../molecules/FooterLinks';
import FooterLogos from '../../molecules/FooterLogos';
import footerStyle from './Footer.module.css';

const FooterSimple = () => {
  const isBottomMenuOpen = useSelector(({ core }) => core.menu.bottomMenuOpen);
  const config = useSelector(({ core }) => core.settings.current.layout.config);
  return (
    <div className={config.footer.specific ? (isBottomMenuOpen ? footerStyle.footerBottomMenu : footerStyle.footerSpecific) : footerStyle.footer}>
      <div className={footerStyle.content}>
        <FooterLinks elements={FOOTER_LINKS} />
        <div className={footerStyle.bottomFooter}>
          <FooterLogos elements={FOOTER_LOGOS} byCategory={true} title='With the financial support of' />
        </div>
      </div>
    </div>
  );
};

export default FooterSimple;
