const config = {
  defaults: {
    header: {
      display: true,
    },
    footer: {
      display: true,
    },
  },
};

export default config;
