import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getAllDisplayData, getAllDisplayDataLocked } from '../pathways/displaySlice';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IDialogSlice {
  state: boolean;
  showModal: boolean;
  options: {
    class: string;
    overlay: string;
    children: string;
    close: boolean;
  };
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IDialogSlice = {
  state: false,
  showModal: false,
  options: {
    class: 'Modal',
    overlay: 'Overlay',
    children: 'Hi',
    close: false,
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    closeDialog(state) {
      state.state = false;
    },
    openDialog(state, action) {
      state.state = true;
      state.options = action.payload;
    },
    calcNew(state) {
      state.showModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllDisplayData.fulfilled, getAllDisplayDataLocked.fulfilled), (state, action) => {
      state.showModal = action.payload.showModal;
    });
  },
});

export const { closeDialog, openDialog, calcNew } = dialogSlice.actions;
export default dialogSlice.reducer;
