import { combineReducers } from '@reduxjs/toolkit';
import calibration from './calibrationSlice';
import categories from './categoriesSlice';
import display from './displaySlice';
import errorManagement from './errorsSlice';
import flows from './flowsSlice';
import general from './generalSlice';
import levers from './leversSlice';
import mainContent from './mainContentSlice';
import metrics from './metricsSlice';

const pathwaysDomain = combineReducers({
  mainContent,
  categories,
  levers,
  general,
  calibration,
  errorManagement,
  metrics,
  display,
  flows,
});

export default pathwaysDomain;
