import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import App from './App';
import globalConfig from './configs/globalConfigs';
import reportWebVitals from './reportWebVitals';
ReactGA.initialize('G-MT88RQDCRK');

const root = ReactDOM.createRoot(document.getElementById('root'));

document.getElementById('metaTitle').innerHTML = globalConfig.META_TITLE;
document.getElementById('metaKeywords').setAttribute('content', globalConfig.META_KEYWORD);

root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
