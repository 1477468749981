import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppContext from '../../../../AppContext';
import { closeTopMenu } from '../../../../store/core/menuSlice';
import Dialog from '../../organisms/Dialog';
import FooterSimple from '../../organisms/FooterSimple';
import HeaderSimple from '../../organisms/HeaderSimple';
import Suspense from '../../organisms/Suspense';
import styles from './SimpleLayout.module.css';

function Layout(props) {
  const dispatch = useDispatch();
  const isTopMenuOpen = useSelector(({ core }) => core.menu.topMenuOpen);
  const config = useSelector(({ core }) => core.settings.current.layout.config);
  const closeMenu = () => {
    if (isTopMenuOpen) {
      dispatch(closeTopMenu());
    }
  };

  const appContext = useContext(AppContext);
  const { routes } = appContext;

  return (
    <>
      <div className={styles.bodyLayout}>
        {config.header.display && <HeaderSimple sticky={config.header.sticky} />}

        <div className=''>
          <Dialog />
          <div onClick={closeMenu}>
            <Suspense>
              <Routes>
                {routes.map((route, id) => (
                  <Route key={id} path={route.path} element={route.redirect ? <Navigate to={route.redirect} /> : <route.component {...props} />} />
                ))}
              </Routes>
            </Suspense>
          </div>
        </div>
        {config.footer.display && <FooterSimple />}
      </div>
    </>
  );
}

export default Layout;
