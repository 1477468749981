import React from 'react';

const AboutConfig = {
  settings: {
    layout: {
      style: 'simple',
      config: {
        header: {
          sticky: true,
        },
      },
    },
  },
  routes: [
    {
      path: '/community',
      component: React.lazy(() => import('../../components/UI/pages/about/Community')),
    },
    {
      path: '/contact-us',
      component: React.lazy(() => import('../../components/UI/pages/about/Contact')),
    },
    {
      path: '/FAQ',
      component: React.lazy(() => import('../../components/UI/pages/about/FAQ')),
    },
    {
      path: '/data',
      component: React.lazy(() => import('../../components/UI/pages/about/Data')),
    },
    {
      path: '/how-to-videos',
      component: React.lazy(() => import('../../components/UI/pages/about/Videos')),
    },
    {
      path: '/model-resources',
      component: React.lazy(() => import('../../components/UI/pages/about/ModelResources')),
    },
    {
      path: '/news',
      component: React.lazy(() => import('../../components/UI/pages/about/News')),
    },
    {
      path: '/legal',
      component: React.lazy(() => import('../../components/UI/pages/about/Legal')),
    },
  ],
};

export default AboutConfig;
