import snakeCase from 'lodash/snakeCase';
import { METRIC_REGION_CODE, OFFICIAL_SOURCE, SECTOR_OR_VECTOR } from '../constants';

export const mapRegions = (regions) =>
  Object.entries(regions).map((entry) => ({
    id: entry[0],
    detailed_scoring: [
      { scoring: entry[1].scoring_data_availability, title: 'Data availability' },
      { scoring: entry[1].scoring_data_updated, title: 'Data updated' },
      { scoring: entry[1].scoring_data_consistency, title: 'Data consistency' },
      { scoring: entry[1].scoring_assumptions_reviewed, title: 'Assumptions reviewed' },
    ],
    descriptions: [
      {
        description: entry[1].description_sources,
        title: 'Sources',
      },
      { description: entry[1].description_assumptions, title: 'Assumptions' },
      { description: entry[1].description_quality, title: 'Quality assurance' },
      { description: entry[1].description_limitations, title: 'Limitations' },
    ],
    isDisabled: entry[1][process.env.REACT_APP_PROJECT === 'citycalc' ? 'CityCalc_webtool_bool' : 'PatEx_webtool_bool'] === 0,
    ...entry[1],
  }));

export const mapRegionsToOptions = (regions) =>
  regions.map((region) => ({
    value: region.id,
    label: region.Region,
    isDisabled: region[process.env.REACT_APP_PROJECT === 'citycalc' ? 'CityCalc_webtool_bool' : 'PatEx_webtool_bool'] === 0,
    parent: region.parent,
    group: region.group,
  }));

export const mapPathways = (pathwaysByRegion, region) => {
  let pathways = [];

  pathways = [
    ...pathways,
    ...Object.entries(pathwaysByRegion).map((entry) => ({
      id: `${region}_${entry[0]}`,
      regionId: region,
      label: entry[0],
      sectorOrVector: entry[1][SECTOR_OR_VECTOR] ? entry[1][SECTOR_OR_VECTOR] : '',
      ...entry[1],
    })),
  ];

  return pathways;
};

export const mapMetrics = (metricsData) => {
  const metrics = [];
  metricsData.map((metric) =>
    metrics.push({
      ...metric,
      id: `${metric[METRIC_REGION_CODE].toLowerCase()}_${metric.lever_name}_${metric.metrics}`,
      data: {
        L1: { title: 'Ambition level 1', data: metric.L1 },
        L2: { title: 'Ambition level 2', data: metric.L2 },
        L3: { title: 'Ambition level 3', data: metric.L3 },
        L4: { title: 'Ambition level 4', data: metric.L4 },
        L5: { title: 'Historic', data: metric.historic },
        L6: { title: 'Current selection', data: metric.lever_value },
      },
      default_values: metric.default_values.map((obj) => {
        let title;

        switch (obj.type) {
          case 'L1':
            title = 'Ambition level 1';
            break;
          case 'L2':
            title = 'Ambition level 2';
            break;
          case 'L3':
            title = 'Ambition level 3';
            break;
          case 'L4':
            title = 'Ambition level 4';
            break;
          case 'current':
            title = 'Current selection';
            break;
          default:
            title = '';
            break;
        }

        return { ...obj, title };
      }),
    }),
  );

  return metrics;
};

export const mapLevers = (leversData) => {
  const levers = [];
  leversData.map((lever, index) =>
    levers.push({
      ...lever,
      id: index,
      headline: lever.headline === 0 ? null : lever.headline,
      group_1: lever.group_1 === 0 ? null : lever.group_1,
      group_2: lever.group_2 === 0 ? null : lever.group_2,
      description: {
        L1: { title: 'Ambition level 1', details: lever['level 1'], color: '#9ABEFD' },
        L2: { title: 'Ambition level 2', details: lever['level 2'], color: '#4A87F0' },
        L3: { title: 'Ambition level 3', details: lever['level 3'], color: '#3763B0' },
        L4: { title: 'Ambition level 4', details: lever['level 4'], color: '#27467D' },
      },
    }),
  );

  return levers;
};

export const mapConstants = (constants, selectedVisualisatio) =>
  constants.map((item) => ({
    label: item.name,
    value: item.value,
    index: item.index,
    isDisabled: item.value === OFFICIAL_SOURCE && selectedVisualisatio === 2 ? true : item.disabled,
  }));

export const mapGraphDetails = (graphDetailsByCategory) => {
  let graphDetails = [];
  Object.keys(graphDetailsByCategory).forEach((category) => {
    Object.keys(graphDetailsByCategory[category]).forEach((key) => {
      graphDetails = [
        ...graphDetails,
        ...graphDetailsByCategory[category][key].map((element, index) => ({
          ...element,
          id: snakeCase(`${category}_${key}_${index}`),
          category,
          subcategory: key,
        })),
      ];
    });
  });
  return graphDetails;
};

export const mapCategories = (mapControl) => [...new Set(mapControl.map((element) => element.category))];

export const mapSubcategories = (mapControl) => {
  const subcategories = [];
  mapControl.forEach((element) => {
    if (!subcategories.find((subcategory) => subcategory.name === element.subcategory && subcategory.category === element.category)) {
      subcategories.push({
        category: element.category,
        name: element.subcategory,
      });
    }
  });
  return subcategories;
};

export const mapVersionInformation = (version) => ({
  versionNumber: `v${version.Major}.${version.Minor}`,
  versionDate: `${version.Date}`,
});

export const mapDashboardStatic = (dashboardStaticPathways) => {
  let metrics = [];
  Object.keys(dashboardStaticPathways).forEach((pathway) => {
    Object.keys(dashboardStaticPathways[pathway]).forEach(() => {
      metrics = [
        ...metrics,
        ...dashboardStaticPathways[pathway].map((element) => ({
          ...element,
          title: element.pathway,
        })),
      ];
    });
  });

  return metrics;
};

export const mapDashboardDynamic = (dashboardDynamic) => {
  const metrics = [];

  dashboardDynamic.detailed_pathway.forEach((element) => {
    element.name = element.title;
    metrics.push(element);
  });

  return metrics;
};
