import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import globalConfig from '../../configs/globalConfigs';
import { mapRegions } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IRegionsSlice {
  loading: boolean;
  error: null | string | undefined;
  ids: [];
  entities: {};
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const regionsAdapter = createEntityAdapter();

const initialState: IRegionsSlice = regionsAdapter.getInitialState({
  loading: false,
  error: null,
  ids: [],
  entities: {},
});

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRegions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllRegions.fulfilled, (state, action) => {
      state.loading = false;
      regionsAdapter.setAll(state, action.payload);
    });
    builder.addCase(getAllRegions.rejected, (state, action) => {
      state.loading = false;
      regionsAdapter.setAll(initialState, {});
      state.error = action.error.message;
    });
  },
});

export default regionsSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */

export const getAllRegions = createAsyncThunk('regions/fetchRegions', async () => {
  const result = await axios.get(globalConfig.API_REGIONS_URL);
  return mapRegions(result.data);
});

/* ----------------------------------------------------------------
      SELECTORS
---------------------------------------------------------------- */

export const regionsSelector = regionsAdapter.getSelectors((state: RootState) => state.globalDomain.regions);
