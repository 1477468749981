import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useCoreDomainSelector } from '../../../../app/store';
import { MENU_ITEMS } from '../../../../constants';
import { toggleTopMenu } from '../../../../store/core/menuSlice';
import menuBurgerStyle from './MenuBurger.module.css';

const MenuBurger = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useCoreDomainSelector(({ menu }) => menu.topMenuOpen);

  const handleMenuIconClick = () => {
    dispatch(toggleTopMenu());
  };

  return (
    <>
      <div className={isMenuOpen ? menuBurgerStyle.container : menuBurgerStyle.containerClose}>
        {isMenuOpen && (
          <>
            <div className={menuBurgerStyle.containerHeader}>2050 Pathways Explorer</div>
            <button className={menuBurgerStyle.closeBtn} onClick={handleMenuIconClick}>
              <MdClose size={25} color='var(--color-text)' />
            </button>
            <ul className={menuBurgerStyle.menuTabsList}>
              {MENU_ITEMS.map((item) => (
                <li key={item.name}>
                  <NavLink className={({ isActive }) => (isActive ? menuBurgerStyle.active : '')} to={item.path} onClick={handleMenuIconClick}>
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default MenuBurger;
