import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import globalConfig from '../../configs/globalConfigs';
import { mapDashboardStatic } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface dataSample {
  data: number[];
  id: string;
  naming_convention: string;
  pathway: string;
  region: string;
  timeAxis: number[];
  title: string;
}

interface keyNumbersSample {
  Title: string;
  Unit: string;
  Value: number | string;
  Year: number | string;
}

interface textsSample {
  ambition: string;
  emissions_per_scenario: string;
  emissions_per_sector: string;
}

interface IDashboardStaticSlice {
  texts: {} | textsSample;
  keyNumbers: {} | { [key: string]: keyNumbersSample };
  loading: boolean;
  error: null | undefined | string;
  data: [] | dataSample[];
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IDashboardStaticSlice = {
  texts: {},
  keyNumbers: {},
  loading: false,
  error: null,
  data: [],
};

const dashboardStaticSlice = createSlice({
  name: 'dashboardStatic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllStaticDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllStaticDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.texts = action.payload.texts;
      state.keyNumbers = action.payload.keyNumbers;
      state.data = action.payload.data;
    });
    builder.addCase(getAllStaticDashboard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default dashboardStaticSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */
export const getAllStaticDashboard = createAsyncThunk('dashboardStatic/fetchDashboardStatic', async (initialPost: { region: string }) => {
  const { region } = initialPost;
  const result = await axios.post(globalConfig.API_DASHBOARD_STATIC, {
    region,
  });
  const texts = result.data.texts;
  const keyNumbers = result.data.key_numbers;
  const data = mapDashboardStatic(result.data.overview_pathways);

  return { texts, keyNumbers, data };
});
