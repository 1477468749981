import { combineReducers } from '@reduxjs/toolkit';
import regions from './regionsSlice';
import pathways from './pathwaysSlice';
import filters from './filtersSlice';

const globalDomain = combineReducers({
  regions,
  pathways,
  filters,
});

export default globalDomain;
