export const VISUALISATION_OPTIONS = [
  {
    name: 'Multi variables (graphs)',
    value: 'compare-scenario',
    index: 0,
    disabled: false,
  },
  {
    name: 'Multi variables (tables)',
    value: 'calibration',
    index: 1,
    disabled: false,
  },
  {
    name: 'Single variable (KPIs)',
    value: 'single-kpi',
    index: 2,
    disabled: false,
  },
];
