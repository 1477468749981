// eslint-disable-next-line max-classes-per-file
import _ from 'lodash';

class EventEmitter {
  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn);
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = (...args) => {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach(
      // eslint-disable-next-line func-names
      function (fn) {
        fn.apply(this, args);
      }.bind(this),
    );
  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}

class Utils {
  static filterArrayByString(mainArr, searchText) {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
  }

  static arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  static getFields(input, field) {
    const output = [];
    for (let i = 0; i < input.length; ++i) output.push(input[i][field]);
    return output;
  }

  static mapOrder(array, order, key) {
    array.sort(function (a, b) {
      const A = a[key];
      const B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }

  static arrayDiff(a1, a2) {
    const a = [];
    const diff = [];
    let i;
    for (i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    for (const k in a) {
      diff.push(k);
    }
    return diff;
  }

  static searchInObj(itemObj, searchText) {
    if (!itemObj) {
      return false;
    }

    const propArray = Object.keys(itemObj);

    for (let i = 0; i < propArray.length; i += 1) {
      const prop = propArray[i];
      const value = itemObj[prop];

      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
    return false;
  }

  static searchInArray(arr, searchText) {
    arr.forEach((value) => {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
      return false;
    });
    return false;
  }

  static countStringInArray = (arr, searchElement) => {
    let counter = 1;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].includes(searchElement)) {
        counter++;
      }
    }
    return counter;
  };

  static searchInString(value, searchText) {
    return value.toLowerCase().includes(searchText);
  }

  static generateGUID() {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  static toggleInArray(item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  static handleize(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/\W+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    routes = routes.map((route) => {
      let auth = config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = _.merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth,
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)];
    });
    return allRoutes;
  }

  static findById(obj, id) {
    let i;
    let childObj;
    let result;

    if (id === obj.id) {
      return obj;
    }

    for (i = 0; i < Object.keys(obj).length; i += 1) {
      childObj = obj[Object.keys(obj)[i]];

      if (typeof childObj === 'object') {
        result = this.findById(childObj, id);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }

  static difference(object, base) {
    function changes(_object, _base) {
      return _.transform(_object, (result, value, key) => {
        if (!_.isEqual(value, _base[key])) {
          result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value;
        }
      });
    }

    return changes(object, base);
  }

  static EventEmitter() {
    return EventEmitter;
  }

  static leverSorted(data) {
    const levers = [...data];
    // Sort by headline
    const leversSorted = _.groupBy(levers, 'headline');
    // For each Headline
    Object.keys(leversSorted).forEach((headline) => {
      leversSorted[headline].forEach((e) => {
        if (e.group_1 === null) {
          e.group_1 = e.headline;
        }
      });
      // Sort by group_1
      leversSorted[headline] = _.groupBy(leversSorted[headline], 'group_1');
      // For each group1
      Object.keys(leversSorted[headline]).forEach((group1) => {
        leversSorted[headline][group1].forEach((e) => {
          if (e.group_2 === null) {
            e.group_2 = e.group_1;
          }
        });
        // Sort by group_1
        leversSorted[headline][group1] = _.groupBy(leversSorted[headline][group1], 'group_2');
      });
    });

    return leversSorted;
  }

  static serieSorted(serie) {
    let newSeries;
    serie?.forEach((element) => {
      if (!element?.data) return;
      const lastIndex = element.data.length - 1;
      const serieCoordinates = element.data[lastIndex];
      const lastYear = serieCoordinates[1];

      element.year = lastYear;
      newSeries = _.sortBy(serie, ['year']);
    });

    return newSeries;
  }

  static sortArrayExceptOneElementAtTheEnd = (arrayToSort, keyToSortFrom, NameOfElementToSortAtTheEnd) => {
    const indexOfElementToSortAtTheEnd = arrayToSort?.findIndex((element) => element[keyToSortFrom] === NameOfElementToSortAtTheEnd);

    const elementToSortAtTheEnd = arrayToSort.splice(indexOfElementToSortAtTheEnd, 1);
    arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
    arrayToSort.push(elementToSortAtTheEnd[0]);

    return arrayToSort;
  };

  static round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }

  static updateUrlParam(key, value) {
    const params = new URLSearchParams(window.location.search);
    if (params.has(key)) {
      params.set(key, value);
    } else {
      params.append(key, value);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  }

  static deleteURLParam(key) {
    const params = new URLSearchParams(window.location.search);
    if (params.has(key)) {
      params.delete(key);
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    }
  }

  static getClosestLowerValue(myArray, myValue) {
    let i = 0;
    while (myArray[++i] < myValue);
    return myArray[--i];
  }

  static getClosestUpperValue(arr, number) {
    let max = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] > number) {
        return arr[i];
      }
      if (arr[i] > max) {
        max = arr[i];
      }
    }
    return max;
  }

  static deleteElementFromObjectByProperty(object, property) {
    const newObject = Object.keys(object).reduce((element, key) => {
      if (key !== property) {
        element[key] = object[key];
      }
      return element;
    }, {});

    return newObject;
  }

  static formatNumberWithPadStart(number, padStart) {
    return number.toString().padStart(padStart, '0');
  }

  static base64ToBase64URL(base64String) {
    // Replace characters to make it URL-safe
    let base64URLString = base64String.replace(/\+/g, '-').replace(/\//g, '_');

    // Remove trailing padding characters
    const paddingIndex = base64URLString.indexOf('=');
    if (paddingIndex !== -1) {
      base64URLString = base64URLString.substring(0, paddingIndex);
    }

    return base64URLString;
  }

  static base64URLToBase64(base64URLString) {
    // Replace characters to make it regular Base64
    let base64String = base64URLString.replace(/-/g, '+').replace(/_/g, '/');

    // Add padding characters if necessary
    const paddingLength = 4 - (base64String.length % 4);
    if (paddingLength !== 4) {
      base64String += '='.repeat(paddingLength);
    }

    return base64String;
  }

  static divideArrayIntoSubarrays(array, subarraySize) {
    const subarrays = [];

    for (let i = 0; i < array.length; i += subarraySize) {
      const subarray = array.slice(i, i + subarraySize);
      subarrays.push(subarray);
    }

    return subarrays;
  }

  static findMinMaxValuesFromArray(arr) {
    const flattenedArray = arr.flat();
    const smallestValue = Math.min(...flattenedArray);
    const largestValue = Math.max(...flattenedArray);
    return { min: smallestValue, max: largestValue };
  }
}

export default Utils;
