import React from 'react';

const PathwaysConfig = {
  settings: {
    layout: {
      style: 'simple',
      config: {
        footer: {
          specific: true,
        },
      },
    },
  },
  routes: [
    {
      path: '/pathways',
      component: React.lazy(() => import('../../components/UI/pages/Pathways')),
    },
    {
      path: '/',
      component: React.lazy(() => import('../../components/UI/pages/Pathways')),
      redirect: '/pathways',
    },
    {
      path: '*',
      redirect: '/pathways',
      component: React.lazy(() => import('../../components/UI/pages/Pathways')),
    },
  ],
};

export default PathwaysConfig;
