import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SCENARIOS, SOURCE_OPTIONS } from '../../constants';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface dynamicLeversSample {
  start_year: number;
  end_year: number;
  curve_type: string;
  relative: number;
  target: number;
}

interface IFiltersState {
  default: {
    region: string;
    source: string;
    pathway: string;
    scenario: string;
    levers: number[] | [];
    url: string;
    dynamicLevers: { [key: string]: dynamicLeversSample } | {};
    dynamicLeversCategories: {};
  };
  global: {
    visualisation: string;
    endYear: number;
    category: string;
    subcategory: string;
  };
  current: {
    region: string;
    source: string;
    pathway: string;
    scenario: string;
    levers: number[] | [];
    url: string;
    dynamicLevers: { [key: string]: dynamicLeversSample } | any;
    dynamicLeversCategories: {};
  };
  locked:
    | {}
    | {
        region: string;
        source: string;
        pathway: string;
        scenario: string;
        levers: number[] | [];
        url: string;
        dynamicLevers: { [key: string]: dynamicLeversSample } | {};
        dynamicLeversCategories: {};
      };
  concat: {
    region: string[];
    source: string;
    visualisation: string;
    pathway: string[];
    levers: number[][];
    dynamicLevers: {}[];
    regionPathway: string[];
  };
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IFiltersState = {
  default: {
    region: '',
    source: SOURCE_OPTIONS[0].value,
    pathway: '',
    scenario: SCENARIOS[0].value,
    levers: [],
    url: '',
    dynamicLevers: {},
    dynamicLeversCategories: {},
  },
  global: {
    visualisation: '',
    endYear: 2050,
    category: '',
    subcategory: '',
  },
  current: {
    region: '',
    source: '',
    pathway: '',
    scenario: SCENARIOS[0].value,
    levers: [],
    url: '',
    dynamicLevers: {},
    dynamicLeversCategories: {},
  },
  locked: {},
  concat: {
    region: [],
    pathway: [],
    source: '',
    visualisation: '',
    levers: [],
    dynamicLevers: [],
    regionPathway: [],
  },
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilter(state, action) {
      state.current = {
        ...state.current,
        [action.payload.name]: action.payload.value,
      };
    },
    updateLockedFilter(state, action) {
      state.locked = {
        ...state.locked,
        [action.payload.name]: action.payload.value,
      };
    },
    updateGlobalFilter(state, action) {
      state.global = {
        ...state.global,
        [action.payload.name]: action.payload.value,
      };
    },
    swapCurrentLockedFilters(state) {
      const current = state.current;
      const locked = 'region' in state.locked ? state.locked : state.default;
      state.current = { ..._.cloneDeep(locked) };
      state.locked = { ..._.cloneDeep(current) };
    },
    lockCurrentFilters(state) {
      state.locked = { ..._.cloneDeep(state.current) };
    },
    resetLockedFilters(state) {
      state.locked = { ...initialState.locked };
      state.concat = { ...initialState.concat };
    },
    updateConcatFilters(state, action) {
      state.concat = {
        region: [...state.concat.region, _.cloneDeep(state.current.region)],
        pathway: [...state.concat.pathway, action.payload.additionalPathway],
        levers: [...state.concat.levers, _.cloneDeep(state.current.levers)],
        dynamicLevers: [...state.concat.dynamicLevers, _.cloneDeep(state.current.dynamicLevers)],
        regionPathway: [...state.concat.regionPathway, action.payload.additionalRegionPathway],
        source: state.current.source,
        visualisation: state.global.visualisation,
      };
    },
    removeConcatFiltersByIndex(state, action) {
      state.concat = { ...action.payload };
    },
  },
});

export const {
  updateFilter,
  updateLockedFilter,
  updateGlobalFilter,
  swapCurrentLockedFilters,
  lockCurrentFilters,
  resetLockedFilters,
  removeConcatFiltersByIndex,
  updateConcatFilters,
} = filterSlice.actions;
export default filterSlice.reducer;
