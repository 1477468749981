import React from 'react';

const DashboardConfig = {
  settings: {
    layout: {
      style: 'simple',
      config: {
        header: {
          sticky: true,
        },
      },
    },
  },
  routes: [
    {
      path: '/dashboard',
      component: React.lazy(() => import('../../components/UI/pages/Dashboard')),
    },
  ],
};

export default DashboardConfig;
