import { useSelector } from 'react-redux';
import { useGlobalDomainSelector } from '../app/store';
import { SINGLE_KPI_SCENARIO, VISUALISATION_OPTIONS } from '../constants';
import { mainContentInformation } from '../store/pathways/mainContentSlice';
import { Utils, mapDataHelper } from '../utils';

export default function useSeriesInformation(firstSerieData, secondSerieData, mapNumber) {
  const { visualisation: selectedVisualisation } = useGlobalDomainSelector(({ filters }) => filters.global);
  const visualisation = VISUALISATION_OPTIONS.find((v) => v.index === selectedVisualisation);
  const customLegend = visualisation.value === SINGLE_KPI_SCENARIO;

  const callInfos = useSelector(mainContentInformation);

  let firstSerie = firstSerieData ? mapDataHelper.getSeries(firstSerieData, callInfos, customLegend, mapNumber) : {};
  const secondSerie = secondSerieData ? mapDataHelper.getSeries(secondSerieData, callInfos, customLegend, mapNumber) : {};

  if (visualisation === SINGLE_KPI_SCENARIO) firstSerie = Utils.serieSorted(firstSerie);

  return { firstSerie, secondSerie };
}
