import { useSelector } from 'react-redux';
import { useGlobalDomainSelector } from '../app/store';
import { SINGLE_KPI_SCENARIO, VISUALISATION_OPTIONS } from '../constants';
import { mainContentInformation } from '../store/pathways/mainContentSlice';
import { Utils, mapDataHelper } from '../utils';

export default function useGraphInformation(mapOne, mapTwo, isLocked, minMax) {
  const { visualisation: selectedVisualisation } = useGlobalDomainSelector(({ filters }) => filters.global);
  const visualisation = VISUALISATION_OPTIONS.find((v) => v.index === selectedVisualisation);
  const customLegend = visualisation.value === SINGLE_KPI_SCENARIO;
  const legend = false;
  const callInfos = useSelector(mainContentInformation);

  let mapOneOptions = mapOne ? mapDataHelper.getOptions(mapOne, callInfos, customLegend, isLocked, legend, 0, minMax?.mapOne) : {};
  let mapTwoOptions = mapTwo ? mapDataHelper.getOptions(mapTwo, callInfos, customLegend, isLocked, legend, 1, minMax?.mapTwo) : {};

  if (visualisation === SINGLE_KPI_SCENARIO) mapOneOptions = Utils.serieSorted(mapOneOptions);

  return { mapOneOptions, mapTwoOptions };
}
