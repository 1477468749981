import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import globalConfig from '../../configs/globalConfigs';
import { MODEL_SOURCE, SINGLE_KPI_SCENARIO } from '../../constants';
import { mapGraphDetails } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IMainContentSlice {
  loading: boolean;
  error: null | string | undefined;
  isMetricsAvailable: boolean;
  ids: [];
  entities: {};
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const mainContentAdapter = createEntityAdapter();

const initialState: IMainContentSlice = mainContentAdapter.getInitialState({
  loading: false,
  error: null,
  isMetricsAvailable: false,
  ids: [],
  entities: {},
});

const mainContentSlice = createSlice({
  name: 'mainContent',
  initialState,
  reducers: {
    updateIsMetricsAvailable(state, action) {
      state.isMetricsAvailable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGraphDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllGraphDetails.fulfilled, (state, action) => {
      state.loading = false;
      mainContentAdapter.setAll(state, action.payload);
    });
    builder.addCase(getAllGraphDetails.rejected, (state, action) => {
      state.loading = false;
      mainContentAdapter.setAll(initialState, []);
      state.error = action.error.message;
    });
  },
});

export const { updateIsMetricsAvailable } = mainContentSlice.actions;
export default mainContentSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */

export const getAllGraphDetails = createAsyncThunk(
  'mainContent/fetchGraphDetails',
  async (initialPost: { source: string; visualisation: string }) => {
    const { source, visualisation } = initialPost;

    let url = source === MODEL_SOURCE ? globalConfig.API_GRAPH_DETAIL_URL : globalConfig.API_GRAPH_DETAIL_URL_OFFICIAL;
    if (visualisation === SINGLE_KPI_SCENARIO) url = globalConfig.API_KPIS_GRAPHS_DETAILS_URL;

    const result = await axios.get(url);

    return mapGraphDetails(result.data);
  },
);

/* ----------------------------------------------------------------
      SELECTORS
---------------------------------------------------------------- */

export const graphDetailsSelector = mainContentAdapter.getSelectors((state: RootState) => state.pathwaysDomain.mainContent);

const globalFilterSelector = (state: RootState) => state.globalDomain.filters.global;

export const mainContentInformation = createSelector<any, { category: string; subcategory: string; Unit: string }[]>(
  graphDetailsSelector.selectAll,
  globalFilterSelector,
  (elements: any, { category, subcategory }: { category: string; subcategory: string }) =>
    elements.filter((element: { category: string; subcategory: string }) => element.category === category && element.subcategory === subcategory) ||
    [],
);
