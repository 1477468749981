import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useCoreDomainSelector } from '../../../../app/store';
import { closeDialog } from '../../../../store/core/dialogSlice';

const Dialog = () => {
  const dispatch = useDispatch();
  const state = useCoreDomainSelector(({ dialog }) => dialog.state);
  const options = useCoreDomainSelector(({ dialog }) => dialog.options);

  return (
    <Modal
      isOpen={state}
      contentLabel='Dialog'
      appElement={document.getElementById('root') as HTMLElement}
      onRequestClose={() => (options.close ? dispatch(closeDialog()) : null)}
      className={options.class}
      overlayClassName={options.overlay}
      style={{
        content: {
          transform: state ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: state ? '1' : '0',
        },
      }}
    >
      {options.children}
    </Modal>
  );
};

export default Dialog;
