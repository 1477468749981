import { useSelector } from 'react-redux';

export default function useGetLastCommonYearAvailable() {
  const currentTimeAxis = useSelector(({ pathwaysDomain }) => pathwaysDomain.display.current.timeAxis);
  const lockedTimeAxis = useSelector(({ pathwaysDomain }) => pathwaysDomain.display.locked.timeAxis);

  let lastYearAvailable;
  if (currentTimeAxis && !lockedTimeAxis) {
    lastYearAvailable = currentTimeAxis[currentTimeAxis.length - 1];
  } else if (!currentTimeAxis && lockedTimeAxis) {
    lastYearAvailable = lockedTimeAxis[lockedTimeAxis.length - 1];
  } else if (currentTimeAxis && lockedTimeAxis) {
    const commonTimeAxis = currentTimeAxis.filter((element) => lockedTimeAxis.includes(element));
    lastYearAvailable = commonTimeAxis[commonTimeAxis.length - 1];
  }

  const lastYear = {
    label: `${lastYearAvailable}`,
    value: lastYearAvailable,
    index: undefined,
    isDisabled: false,
  };
  return { lastYearAvailable, lastYear };
}
