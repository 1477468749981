import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import globalConfig from '../../configs/globalConfigs';
import { MODEL_SOURCE } from '../../constants';
import { mapPathways } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IPathwaysSlice {
  loading: boolean;
  error: null | string | undefined;
  ids: [];
  entities: {};
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const pathwaysAdapter = createEntityAdapter();

const initialState: IPathwaysSlice = pathwaysAdapter.getInitialState({
  loading: false,
  error: null,
  ids: [],
  entities: {},
});

const pathwaysSlice = createSlice({
  name: 'pathways',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPathways.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPathways.fulfilled, (state, action) => {
      state.loading = false;
      pathwaysAdapter.setAll(state, action.payload);
    });
    builder.addCase(getAllPathways.rejected, (state, action) => {
      state.loading = false;
      pathwaysAdapter.setAll(initialState, {});
      state.error = action.error.message;
    });
  },
});

export default pathwaysSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */
export const getAllPathways = createAsyncThunk('pathways/fetchPathways', async (initialPost: { region: string; source: string }) => {
  const { source, region } = initialPost;
  const result = await axios.post(source === MODEL_SOURCE ? globalConfig.API_PATHWAYS_URL : globalConfig.API_OFFICIAL_PATHWAYS_URL, { region });
  return mapPathways(result.data, region);
});

/* ----------------------------------------------------------------
      SELECTORS
---------------------------------------------------------------- */
export const pathwaysSelector = pathwaysAdapter.getSelectors((state: RootState) => state.globalDomain.pathways) as any;
