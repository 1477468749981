import { createBrowserHistory } from 'history';
import Provider from 'react-redux/es/components/Provider';
import { BrowserRouter } from 'react-router-dom';
import '../src/styles/global.css';
import AppContext from './AppContext';
import { store } from './app/store';
import ScrollToTop from './components/layouts/organisms/ScrollToTop';
import MainLayout from './components/layouts/pages/MainLayout';
import routes from './configs/routesConfig';
const history = createBrowserHistory();

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ScrollToTop />
          <MainLayout />
        </BrowserRouter>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
