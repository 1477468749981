import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import globalConfig from '../../configs/globalConfigs';
import { mapVersionInformation } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      TYPES
---------------------------------------------------------------- */

interface IGeneralState {
  versionNumber: string;
  versionDate: string;
  loading: boolean;
  error: undefined | string;
}

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState: IGeneralState = {
  versionNumber: '',
  versionDate: '',
  loading: false,
  error: undefined,
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVersionInformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVersionInformation.fulfilled, (state, action) => {
      state.loading = false;
      state.versionNumber = action.payload.versionNumber;
      state.versionDate = action.payload.versionDate;
    });
    builder.addCase(getVersionInformation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.versionNumber = initialState.versionNumber;
      state.versionDate = initialState.versionDate;
    });
  },
});

export default generalSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */

export const getVersionInformation = createAsyncThunk('general/fetchVersion', async () => {
  const result = await axios.get(globalConfig.API_VERSION_URL);
  return mapVersionInformation(result.data);
});
