import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import globalConfig from '../../configs/globalConfigs';
import { mapLevers } from '../../utils/entity-utils';

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const leversAdapter = createEntityAdapter();

const initialState = leversAdapter.getInitialState({
  loading: false,
  error: null,
});

const leversSlice = createSlice({
  name: 'levers',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllLevers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllLevers.fulfilled, (state, action) => {
      state.loading = false;
      leversAdapter.setAll(state, action.payload);
    });
    builder.addCase(getAllLevers.rejected, (state, action) => {
      state.loading = false;
      leversAdapter.setAll(initialState);
      state.error = action.error.message;
    });
  },
});

export default leversSlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */

export const getAllLevers = createAsyncThunk('levers/fetchLevers', async (initialPost) => {
  const { region, maxYear } = initialPost;

  const result = await axios.post(globalConfig.API_LEVERS_URL, {
    region,
    max_year: maxYear,
  });
  return mapLevers(result.data);
});

/* ----------------------------------------------------------------
      SELECTORS
---------------------------------------------------------------- */

export const leversSelector = leversAdapter.getSelectors((state) => state.pathwaysDomain.levers);
