import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import * as displayHelper from './helpers/displayHelper';

/* ----------------------------------------------------------------
      SLICE (ACTIONS/REDUCERS)
---------------------------------------------------------------- */

const initialState = {
  current: {
    mapOne: [],
    mapTwo: [],
    kpiList: {},
    timeAxis: null,
    isLoaded: false,
  },
  locked: {
    mapOne: [],
    mapTwo: [],
    kpiList: {},
    timeAxis: null,
    isLoaded: false,
  },
  concat: {
    mapOneMetrics: {},
  },
  modalMessage: 'default',
  currentLoading: false,
  lockedLoading: false,
  currentError: '',
  lockedError: '',
};

const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    deleteGraphData(state) {
      state.current = { ...initialState.current };
      state.concat = { ...initialState.concat };
    },
    deleteLockedGraphData(state) {
      state.locked = { ...initialState.locked };
      state.concat = { ...initialState.concat };
    },
    setMapsAreLoading(state) {
      state.locked.isLoaded = false;
      state.current.isLoaded = false;
    },
    deleteFromSeries(state, action) {
      const index = action.payload;
      const lockedSeries = _.cloneDeep(state.locked.mapOne);
      const newLockedSeries = lockedSeries.filter((serie) => serie.id !== Number(index));

      state.mapOne = newLockedSeries;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDisplayData.pending, (state) => {
      state.currentLoading = true;
      state.current.isLoaded = false;
    });
    builder.addCase(getAllDisplayData.fulfilled, (state, action) => {
      state.currentLoading = false;
      state.modalMessage = action.payload.modalMessage;
      state.current = {
        mapOne: action.payload.mapOne,
        mapTwo: action.payload.mapTwo,
        timeAxis: action.payload.timeAxis,
        kpiList: action.payload.kpiList,
        isLoaded: true,
      };
    });
    builder.addCase(getAllDisplayData.rejected, (state, action) => {
      state.currentLoading = false;
      state.currentError = action.error.message;
      state.current = { ...initialState.current };
    });
    builder.addCase(getAllDisplayDataLocked.pending, (state) => {
      state.lockedLoading = true;
      state.locked.isLoaded = false;
    });
    builder.addCase(getAllDisplayDataLocked.fulfilled, (state, action) => {
      state.lockedLoading = false;
      state.modalMessage = action.payload.modalMessage;
      state.locked = {
        mapOne: action.payload.mapOne,
        mapTwo: action.payload.mapTwo,
        timeAxis: action.payload.timeAxis,
        kpiList: action.payload.kpiList,
        isLoaded: true,
      };
    });
    builder.addCase(getAllDisplayDataLocked.rejected, (state, action) => {
      state.lockedLoading = false;
      state.lockedError = action.error.message;
      state.locked = { ...initialState.locked };
    });
  },
});

export const { deleteGraphData, deleteLockedGraphData, setMapsAreLoading, deleteFromSeries } = displaySlice.actions;
export default displaySlice.reducer;

/* ----------------------------------------------------------------
      MIDDLEWARE - API CALLS
---------------------------------------------------------------- */

export const getMapDisplayData = (data, callInfos, graphDetailsToCompare, url, isLocked) => {
  const { modalMessage, showModal } = displayHelper.getModalInfo(Object.keys(data).length, url);
  const kpiList = displayHelper.getKpiList(data?.kpi) || [];
  const mapOne = displayHelper.getResult(data.outputs, callInfos, graphDetailsToCompare, isLocked, 0) || [];
  const mapTwo = displayHelper.getResult(data.outputs, callInfos, graphDetailsToCompare, isLocked, 1) || [];
  const timeAxis = mapOne.length > 0 ? mapOne[0].timeAxis : [];

  return { mapOne, mapTwo, kpiList, modalMessage, showModal, timeAxis };
};

export const getAllDisplayData = createAsyncThunk('display/fetchMapData', async (initialPost) => {
  const { data, url, graphDetailsToCompare, callInfos, isLocked } = initialPost;

  const result = await axios.post(url, data);
  const { mapOne, mapTwo, kpiList, modalMessage, showModal, timeAxis } = getMapDisplayData(
    result.data,
    callInfos,
    graphDetailsToCompare,
    url,
    isLocked,
  );

  return { mapOne, mapTwo, kpiList, modalMessage, showModal, timeAxis };
});

export const getAllDisplayDataLocked = createAsyncThunk('display/fetchMapDataLocked', async (initialPost) => {
  const { data, url, graphDetailsToCompare, callInfos, isLocked } = initialPost;

  const result = await axios.post(url, data);
  const { mapOne, mapTwo, kpiList, modalMessage, showModal, timeAxis } = getMapDisplayData(
    result.data,
    callInfos,
    graphDetailsToCompare,
    url,
    isLocked,
  );
  return { mapOne, mapTwo, kpiList, modalMessage, showModal, timeAxis };
});
